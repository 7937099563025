@import url('https://fonts.googleapis.com/css2?family=Lemon&display=swap');

@font-face {
    font-family: 'Lemon', cursive;
    src: url('https://fonts.googleapis.com/css2?family=Lemon&display=swap');
  }

.major {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.major__intro {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.major__button {
    cursor: pointer;
    padding: 8px 24px;
    border-radius: 6px;
    border: 2px solid #70089F;
    background-color: #47066E;
    color: #FECE00;
    text-transform: uppercase;
    font-family: 'Lemon', Helvetica, sans-serif;
}

.major__title {
    font-family: 'Lemon', Helvetica, sans-serif;
    margin-top: 0px;
    font-size: 20px;
    margin-bottom: 20px;
    color: #FFFFFF;
}

.loading-screen {
    width: 100vw;
    height: 100vh;
    background-color: #100f0f;
}