@import url('https://fonts.googleapis.com/css2?family=Lemon&display=swap');

@font-face {
    font-family: 'Lemon', cursive;
    src: url('https://fonts.googleapis.com/css2?family=Lemon&display=swap');
  }


html {
    height: 100%;
    background-image:url("../graphics/bg-main.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

body {
    
    /* background-color:  rgba(18, 2, 31, 0.95) ; */
    backdrop-filter: blur(4px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#bd {
    margin: 0 auto;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    position: relative;
}

.main {
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    position: relative;
}

.cards-cascade {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    text-align: center;
}

.main__start-button {

        cursor: pointer;
        padding: 10px 40px;
        border-radius: 6px;
        text-transform: uppercase;
        font-family: 'Lemon', Helvetica, sans-serif;
        margin-bottom: 15px;
}

.game-name {
    color: #fff;
    margin-top: 8px;
}

#clicks {
    margin-top: 10px;
    margin-bottom: 0px;
    color: #FF5C00;
    
}

.logo {
    /* border: 2px solid #fff; */
    border-radius: 8px;
    margin-bottom: 20px;
}


.success {
    position: absolute;
    padding: 20px 25px;
    background-color: #FF5C00;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 2px solid #fff;
    border-radius: 10px;
    backdrop-filter: (2px);
    display: none;
    z-index: 999;
    max-width: 300px;
    
}

.success__heading {
    margin-top: 0px;
    color: #fff;
    margin-bottom: 14px;
}

.success__paragraph {
    margin-top: 0px;
    color: #fff;
}

.success__button {
    border: 2px solid #fff;
    text-transform: uppercase;
    color: #FF5C00;
    font-weight: 900;
    background-color: #270E4A;
    padding: 8px 0;
    border-radius: 10px;
}

.disabled {
    pointer-events: none;
    border: 2px solid #9c9c9c;
    background-color: #656565;
    color: #fdfdfd;
}

.enabled {
    border: 2px solid #70089F;
    background-color: #47066E;
    color: #FECE00;
}



