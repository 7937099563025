.card {
    position: relative;
}

.card__img {
    height: 75px;
    width: auto;
    display: block;
    border: none;
}

.card .card__front {
    transform: rotateY(90deg);
    transform: all ease-in 0.1s;
    position: absolute;
}

.flipped .card__front {
    transform: rotateY(0deg);
    transition-delay: 0.1s;
}

.card__front {
    cursor: pointer;
}

.card__back {
    cursor: pointer;
}

.card .card__back {
    transition: all ease-in 0.1s;
    transition-delay: 0s;
}

.flipped .card__back {
    transform: rotateY(90deg);
    transition-delay: 0s;
}