@import "https://fonts.googleapis.com/css2?family=Lemon&display=swap";
@import "https://fonts.googleapis.com/css2?family=Lemon&display=swap";
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.App {
  text-align: center;
}

.mainApp {
  max-width: fit-content;
  text-align: center;
  margin: 0 auto;
}

.game__loading {
  color: #fff;
  background-color: #ff5c00;
  margin: 0;
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin 20s linear infinite;
  }
}

.App-header {
  min-height: 100vh;
  color: #fff;
  background-color: #282c34;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.App-link {
  color: #61dafb;
}

.attribution {
  color: #fff;
  opacity: .5;
  text-align: center;
  margin: 15px auto 0;
  font-size: 8px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card {
  position: relative;
}

.card__img {
  height: 75px;
  width: auto;
  border: none;
  display: block;
}

.card .card__front {
  transform: rotateY(90deg);
  transform: all ease-in .1s;
  position: absolute;
}

.flipped .card__front {
  transition-delay: .1s;
  transform: rotateY(0deg);
}

.card__front, .card__back {
  cursor: pointer;
}

.card .card__back {
  transition: all .1s ease-in;
}

.flipped .card__back {
  transition-delay: 0s;
  transform: rotateY(90deg);
}

@font-face {
  src: url("https://fonts.googleapis.com/css2?family=Lemon&display=swap");
}

html {
  height: 100%;
  background-image: url("bg-main.4bd0bea6.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

body {
  backdrop-filter: blur(4px);
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

#bd {
  background-repeat: no-repeat;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.main {
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  display: flex;
  position: relative;
}

.cards-cascade {
  grid-gap: 20px;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
  display: grid;
}

.main__start-button {
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 10px 40px;
  font-family: Lemon, Helvetica, sans-serif;
}

.game-name {
  color: #fff;
  margin-top: 8px;
}

#clicks {
  color: #ff5c00;
  margin-top: 10px;
  margin-bottom: 0;
}

.logo {
  border-radius: 8px;
  margin-bottom: 20px;
}

.success {
  backdrop-filter: (2px);
  z-index: 999;
  max-width: 300px;
  background-color: #ff5c00;
  border: 2px solid #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 25px;
  display: none;
  position: absolute;
}

.success__heading {
  color: #fff;
  margin-top: 0;
  margin-bottom: 14px;
}

.success__paragraph {
  color: #fff;
  margin-top: 0;
}

.success__button {
  text-transform: uppercase;
  color: #ff5c00;
  background-color: #270e4a;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 8px 0;
  font-weight: 900;
}

.disabled {
  pointer-events: none;
  color: #fdfdfd;
  background-color: #656565;
  border: 2px solid #9c9c9c;
}

.enabled {
  color: #fece00;
  background-color: #47066e;
  border: 2px solid #70089f;
}

@font-face {
  src: url("https://fonts.googleapis.com/css2?family=Lemon&display=swap");
}

.major {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.major__intro {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.major__button {
  cursor: pointer;
  color: #fece00;
  text-transform: uppercase;
  background-color: #47066e;
  border: 2px solid #70089f;
  border-radius: 6px;
  padding: 8px 24px;
  font-family: Lemon, Helvetica, sans-serif;
}

.major__title {
  color: #fff;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: Lemon, Helvetica, sans-serif;
  font-size: 20px;
}

.loading-screen {
  width: 100vw;
  height: 100vh;
  background-color: #100f0f;
}

/*# sourceMappingURL=index.6ebf2cf7.css.map */
